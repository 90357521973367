import React from "react"
import { SiWebpack } from "react-icons/si"
import { BsFiletypeJpg } from "react-icons/bs"

function ToolInfo(props) {
  return (
    <div>
      <div className="page-steps container">
        <h1 className="page-text">
          How to use a JPG to WebP converter
        </h1>
        <span className="page-text01">

Elevate your image presentation with our JPG to WebP converter, effortlessly converting JPG files into streamlined WebP format, optimized for efficient online use without compromising quality.          </span>
        <div className="page-container01">
          <div className="page-step">
            <div className="page-container02">
              <div className="page-line"/>
              <div className="page-container03">
                <BsFiletypeJpg size={30} style={{ color: "#00000080" }} />
              </div>
              <div className="page-line1"></div>
            </div>
            <div className="page-container04">
              <h1 className="page-text02"> Click on "Upload JPG image file" and select
                the JPG file from your computer that you want to convert.</h1>
            </div>
          </div>

          <div className="page-step3">
            <div className="page-container11">
              <div className="page-line6"/>
              <div className="page-container12">

                <SiWebpack size={26} style={{color: "#00000080"}}/>
              </div>
              <div className="page-line7"/>
            </div>
            <div className="page-container13">
              <h3 className="page-text10">Once the conversion is complete, WebP file will automatically downloaded in your device.</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ToolInfo